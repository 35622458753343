$(function(){

    let dom = {};
    const tbl = 'doc_cat';
    const name = 'Category'
    
    function setupDom(recache)
    {
        if( recache ) {
            dom.delete = dom.table.find('.btn-js-delete').not('.btn-js');
            return;
        }
        
        dom.table = app.DOM.form.find('table');
        dom.delete = dom.table.find('.btn-js-delete');
        dom.add = dom.table.parent().find('.btn-js-add');
        
        // rows
        const $tr = dom.table.find('tbody tr');
        
        dom.tr = $tr.not('.template');
        dom.tr_template = $tr.filter('.template');
        dom.input_template = dom.tr_template.find('input[type="text"]');

        return dom;
    }
    
    function addRow(dom)
    {
        // remove any errors
        dom.input_template.removeClass('error');
        
        const ts = new Date().valueOf().toString().substr(0,11);
        const html = dom.tr_template.html()
                      .replace('Add', 'Delete')
                      .replace('add','delete')
                      .replace('plus', 'minus')
                      .replace('green', 'red')
                      .replace('value=""', `value="${dom.input_template.val()}"`)
                      .replace(`${tbl}_template`, `${tbl}[${ts}]`);

        // append
        dom.tr_template.after(`<tr class="pending">${html}</tr>`);
        
        // reset: inputs
        dom.input_template.filter('input').each(function(){ $(this).val('');});
        
        // get dom object
        setupDom(true);
        setupEvents(false);
    }
    
    function setupEvents(init)
    {
        // ADD
        if( init ) {
            dom.add.on('click', function(){

                console.log('e');

                if( dom.input_template.val() ) {
                    addRow(dom);
                } else {
                    dom.input_template.addClass('error');
                }                
            });
        }
                
        // DELETE
        dom.delete.not('.btn-js').on('click', function(){
            
            const $tr = $(this).closest('tr');
            const count = $tr.find('td.col_count_doc').text().trim();
            const msg = ( count === '0' || count === '-' || !count ) ? '' : `

This Cost ${name} is currently assigned to ${count} Document(s). The associated document(s) will now be transferred to the "Other" Document ${name}`;

            // do nothing
            if( confirm(`Are you sure you want to remove this Cost ${name}?${msg}`) === false ) {
                return;
            }
            
            // remove input
            $tr.find('input[type="text"]').remove();
            
            // hide <tr>
            $tr.hide();
            
        }).addClass('btn-js');
    }

    // get dom object
    dom = setupDom();

    // attach events to dom object
    setupEvents(true);

});